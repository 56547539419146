<template>
    <div class="meridian food zhong">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>经络穴位</h3>
                <p>JING LUO XUE WEI</p>
            </div>
        </div>
        <div class="content">
            <div class="content-box clearfix">
                <div class="content-left">
                    <div class="sidenav">
                        <h3 @click="searchMeridian(0)">全部经络</h3>
                        <el-menu class="el-menu-vertical-demo" :unique-opened="true">
                            <el-submenu v-for="(sideNav, index) in MeridianNavList" :index="index+ ''" :key="index">
                                <template slot="title">
                                    <span>{{ sideNav.categoryName }}</span>
                                </template>
                                <div class="subtitle">
                                    <el-menu-item-group>
                                        <el-menu-item v-for="(subItem, i) in sideNav.childrens" :key="i"
                                            :index="index+''" @click="searchMeridian(subItem.categoryId)">
                                            <template slot="title">
                                                {{ subItem.categoryName }}
                                            </template>
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </div>
                            </el-submenu>
                        </el-menu>
                    </div>
                </div>
                <div class="content-right">
                    <div class="result-tit">
                        <p>为您找到 <span>{{ total }}</span><em>个结果</em></p>
                    </div>
                    <ul class="food-list">
                        <li class="food-item" v-for="(meridianDetailItem, i) in MeridianDetailList">
                            <div>
                                <h3 class="food-tit" @click="toMeridianDetail(meridianDetailItem.hashKey)">
                                    {{ meridianDetailItem.meridianName }}</h3>
                                <p>包含穴位：
                                    <!-- <el-button plain size="mini" style="margin: 5px;" type="warning"
                                        v-for="(meridianTag, i) in meridianDetailItem.acupoints" :key="i"
                                        @click="toAcupointDetail(meridianTag.hashKey)">
                                        {{ meridianTag.acupointName }}
                                    </el-button> -->
                                    <el-button plain size="mini" style="margin: 5px;" type="warning"
                                        v-for="(meridianTag,i) in meridianDetailItem.acupoints" :key="i"
                                        @click="toAcupointDetail (meridianTag.hashKey)"
                                        v-html="meridianTag.acupointName">
                                        {{meridianTag.acupointName}}
                                    </el-button>
                                </p>
                            </div>
                        </li>
                    </ul>
                    <div id="page">
                        <el-pagination background layout="prev, pager, next,total" :page-size="15"
                            @current-change="handleCurrentChange" :current-page.sync="currentPage" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import hdCom from '../../components/header' //引入组件
import ftCom from '../../components/footer' //引入组件
import rtBar from "../../components/right";
import { Meridian } from '../../components/Disease/Meridian'
import { toAcupoint, toMeridian } from "../../utils/relation";
var pageIndex = 1
var categoryId = 0
var keyword = ''
var searchId = 0;
export default {
    name: "MeridianBank",
    components: {
        hdCom,
        ftCom,
        rtBar
    }, data() {
        var meridian = new Meridian(this.TokenClient, this.Services.Disease)
        return {
            MeridianDomain: meridian,
            checkedhot: [],
            hots: [],
            MeridianNavList: [],
            MeridianDetailList: [],
            total: 0,
            currentPage: 1, //初始页
            rloading: [],//loading遮罩
        }
    },
    mounted() {
        this.$bus.$emit('isShowChangeSize');
        this.$bus.$emit('select', 'meridian');
        this.getMeridianNavListData();
        // categoryId = this.$route.query.categoryId
        keyword = this.$route.query.keyword
        this.searchMeridian(categoryId);
    },
    methods: {
        //左侧分类获取
        getMeridianNavListData: function () {
            var _this = this;
            _this.MeridianDomain.Categorys(function (data) {
                _this.MeridianNavList = (data.data);
                _this.MeridianNavList.forEach((item) => {
                    if (item.childrens && item.childrens.length > 0) {
                        item.childrens.unshift({
                            categoryId: item.categoryId,
                            categoryName: "全部"
                        })
                    } else {
                        item.childrens = [{
                            categoryId: item.categoryId,
                            categoryName: "全部"
                        }]
                    }
                })
            },
                function (error) {
                    console.log("经络穴位分类请求失败:", error)
                })
        },
        /*//收藏
        onCollection: function (item) {
            var _this = this;
            _this.collectionDomain.Put("food",item.foodMaterialPrescriptionName,item.hashKey,item.foodMaterialPrescriptionId,0,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '收藏成功!'
                    });
                }, function (err) {
                    if(err.status === 400)
                    {
                        _this.$message.error('请勿重复收藏!');
                    }
                });
        },*/
        handleOpen(key, keyPath) {
        },
        handleClose(key, keyPath) {
        },
        //右侧经络穴位列表获取
        searchMeridian: function (categoryType, isPage = false) {
            categoryId = categoryType;
            var _this = this;
            _this.rloading = _this.openLoading()
            if (!isPage) pageIndex = 1
            if (categoryId == undefined) categoryId = 0
            let params = `/${categoryId}/${pageIndex}`
            if (keyword) {
                params += `/${keyword}`;
            }
            else {
                params += '/%20';
            }
            _this.MeridianDomain.Meridians(params, function (data) {
                $('.highlights-text').parent().removeClass('foucs')
                if (data.data.results) {
                    data.data.results.forEach(item => {
                        if (item.acupoints && item.acupoints.length > 0) {
                            item.acupoints.forEach(itemAcupoints => {
                                if (itemAcupoints.acupointName && keyword) {
                                    // 匹配关键字正则
                                    let replaceReg = new RegExp(keyword, 'g');
                                    // 高亮替换v-html值
                                    let replaceString = '<span class="highlights-text">' + keyword + '</span>';
                                    // 开始替换
                                    itemAcupoints.acupointName = itemAcupoints.acupointName.replace(replaceReg, replaceString);
                                }
                            });
                        }
                    });
                    _this.MeridianDetailList = data.data.results
                }
                _this.total = data.data.dataTotal;
                _this.rloading.close()
                _this.$nextTick(() => {
                    $('.highlights-text').parent().addClass('foucs')
                })
            },
                function (error) {
                    console.log("经络穴位列表请求失败:", error)
                })
            if (!isPage) {
                this.currentPage = 1
            }
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0
        },
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum
            pageIndex = pageNum
            this.searchMeridian(categoryId, true);
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0
        },
        //跳转穴位详情
        toAcupointDetail(hashKey) {
            toAcupoint(hashKey);
        },
        //跳转经络详情
        toMeridianDetail(hashKey) {
            toMeridian(hashKey);
        },
    },
    created() {

    },
    watch: {
        $route() {
            keyword = this.$route.query.keyword
            this.searchMeridian(categoryId);
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/zhongyaoItem.css";
@import "../../assets/css/footer.css";

.food-list .food-item {
    position: relative;
    padding: 15px 20px;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.08);
}

.food-list .food-item:hover {
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
}

.food-tit {
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    cursor: pointer;
}

.food-tit:hover {
    color: #b76c12;
}

.food-item p {
    margin-top: 10px;
    color: #666666;
}

.food-item p em {
    font-style: normal;
}

.sc-btn i {
    position: absolute;
    right: 43px;
    top: 15px;
    font-size: 26px;
    color: #b76c12;
    height: 36px;
    line-height: 36px;
    display: block;
    padding: 0 20px;
}
</style>
<style>
.meridian .bar-list .yysc {
    display: none !important;
}

.meridian .el-button--mini,
.el-button--small {
    font-size: 13px;
}

.foucs {
    background: #E6A23C !important;
    border-color: #E6A23C !important;
    color: #ffffff !important;
}
</style>
<!-- <style>
   .highlights-text {
       background: #ff9632;
       color: #333;

   }
 </style>-->
